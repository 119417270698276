<template>
	<v-row>
		<v-col cols="12">
			<Table
				:pgLimitGet="pgLimit"
				backendDirectory="ti/cronjob/db2"
				:busca="{
					taskid: busca.taskid || null,
                }"
				:filter="true"
				:keydown="keydown"
				:headers="{
                    taskid: {nome: 'Task ID', tipo: 'texto'},
                    cronjob: {nome: 'Cronjob', tipo: 'texto'},
                    mensagem: {nome: 'Mensagem', tipo: 'texto'},
                    status: {nome: 'Status', tipo: 'texto'},
                    sqlcode: {nome: 'SQL Code', tipo: 'texto'},
                    sqlstate: {nome: 'SQL State', tipo: 'texto'},
                    rc: {nome: 'RC', tipo: 'texto'},
                    dtinicio: {nome: 'Data Inicial', tipo: 'texto'},
                    dtfim: {nome: 'Data Final', tipo: 'texto'},
                    duracao: {nome: 'Duração execução', tipo: 'texto', class: 'text-truncate'},
                }"
			>
				<v-col class="pr-1 d-none d-md-block">Logs DB2 Comissão</v-col>
				<v-col class="px-1">
					<v-select
						label="Task ID"
						outlined
						clearable
						dense
						:items="cronjobs"
						item-text="cronjob"
						item-value="taskid"
						v-model="busca.taskid"
					/>
				</v-col>
			</Table>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Table from "../Widgets/Table";

export default {
	name: "TiCronDB2",
	components: { Table },
	data: () => ({
		busca: {},
		keydown: false,
		cronjobs: [],
	}),
	computed: {
		...mapState(["pgLimit", "usuario", "backendUrl"]),
	},
	methods: {
		listarCronjobs() {
			return axios
				.post(`${this.backendUrl}ti/cronjob/db2/filtro`, {
					limit: 99999,
					offset: 0,
				})
				.then((res) => {
					this.cronjobs = res.data;
					this.cronjobs = this.cronjobs.map((v) => {
						return {
							taskid: v.taskid,
							cronjob: `${v.taskid} - ${v.cronjob}`,
						};
					});
				});
		},
	},
	created() {
		this.listarCronjobs();
	},
};
</script>